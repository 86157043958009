import React, { useState, useEffect } from 'react';
import './form.css';
import '../../ThankYou/thankyou.css';
import { useUserData } from '../../../userData';
import { useTranslation } from 'react-i18next';
import { emailValidator } from '../../Signup/Form/validators';
import Field from '../../Onboarding/Question/Field';
import { useForm } from 'react-hook-form';
import { sendAmplitudeData } from '../../../res/amplitude';
import { signup } from '../../Signup/Form/signup';
import DownloadIcon from './download.svg';
import { webapp } from '../../../config';
import { login } from '../../ThankYou/GoToWeb';
import { GTMsendEvent } from '../../../res/gtag';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';

const capitalLetterRegex = /([A-Z])/;
const letterRegex = /([a-z])/;
const numberRegex = /([0-9])/;

const Form = () => {
  const { userData, setUserData } = useUserData(); //set UserData for thank you modal
  const [status, setStatus] = useState(''); // status either loading/failed/empty/redirect
  const [formData, setFormData] = useState({}); // Contains all the form data from the steps
  const [continuationToken, setContinuationToken] = useState('');
  const [attempts, setAttempts] = useState(0);
  const { t } = useTranslation();

  const checkEmail = async (email) => {
    const emailExists = await emailValidator(email);
    if (!emailExists) {
      if (userData.rasoRef)
        setStatus('redirect');
      else
        window.location.replace(webapp);
    }
    return emailExists;
  }
  useEffect(() => {
    const checkEmailAndPromo = async () => {
      await checkEmail(userData.email);
      if (userData.promocode) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          promocode: userData.promocode,
        }));
      }
    };

    checkEmailAndPromo();
  }, [userData]);

  useEffect(() => {
    sendAmplitudeData('WEB_SIGNUP_TABVIEW', {
      tab: userData.rasoRef ? 'RASO' : 'password',
    });
  }, []);
  const { register, handleSubmit, watch, errors } = useForm({
    mode: 'onBlur',
  });
  const password = watch('password');
  const nextStep = async (data) => {
    if (attempts > 5) return;
    console.log('attempts: ', attempts);
    setAttempts(attempts + 1);
    // Go to next step if last step submit to backend
    const newData = { ...formData, ...data, language: t('current_language'), attempts };
    setFormData(newData);
    setStatus('loading');
    console.log('heheeeee1');
    const emailExists = await checkEmail(newData.email);
    if (!emailExists) return;
    console.log('heheeeee');

    const auth_continuation_token = await signup(newData);
    if (!auth_continuation_token) {
      setStatus('failed');
      return;
    }
    GTMsendEvent("raso_created_account");
    setContinuationToken(auth_continuation_token);
    setUserData(
      {
        ...userData,
        email: newData.email,
        phoneNumber: newData.phoneNumber,
        firstname: newData.firstname,
        lastname: newData.lastname,
      },
      true
    );
    if (!userData.rasoRef) {
      setStatus('loading')
      await goToWeb(auth_continuation_token);
    } else if (auth_continuation_token) setStatus('redirect');
    else {
      setStatus('failed');
    }
  };

  const onSubmit = (data) => nextStep({ ...userData, ...data });

  const goToWeb = async (auth_continuation_token) => {
    try {
      sendAmplitudeData('WEB_SIGNUP_CLICKEDWEB');
      window &&
        window.location.replace(
          webapp + '/?auth_continuation_token=' + auth_continuation_token
        );
    } catch (e) {
      console.log('login error', e);
    }
  };
  return (
    <>
      {status !== 'redirect' ? (
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
          <span className='question_subhead question_subhead--smaller'>
            {t('raso_password_subhead')}
          </span>
          <span className='question_head question_head--smaller'>
            {t('raso_password_head')}
          </span>
          <Field
            type='password'
            name='password'
            label={t('password_label')}
            placeholder={t('password_placeholder')}
            ref={register({
              required: true,
              minLength: 8,
              maxLength: 80,
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
            })}
            autoComplete='new-password'
            errors={errors}
            watch={watch}
          />

          <button className='form_submit_btn' disabled={status === 'loading'}>
            {t('form_continue')}
          </button>
          <div className='field_error'>
            {errors['password'] &&
              (password ? (
                <>
                  {!capitalLetterRegex.test(password) && (
                    <span>{t('password_must_contain_capital')}</span>
                  )}
                  {!letterRegex.test(password) && (
                    <span>{t('password_must_contain_letters')}</span>
                  )}
                  {!numberRegex.test(password) && (
                    <span>{t('password_must_contain_numbers')}</span>
                  )}
                  {errors['password'].type === 'minLength' && (
                    <span>{t('password_must_be_six_chars_min')}</span>
                  )}
                  {errors['password'].type === 'maxLength' && (
                    <span>{t('password_must_be_eighty_chars_max')}</span>
                  )}
                </>
              ) : (
                (errors['password'].type === 'pattern' ||
                  errors['password'].type === 'minLength' ||
                  errors['password'].type === 'maxLength') && (
                  <span>{t('field_invalid')}</span>
                )
              ))}

            {(status === 'failed' || attempts >= 5) && (
              <span>{t('form_error')}</span>
            )}
          </div>
        </form>
      ) : (
        <>
          {userData.rasoRef ?
            <>
              <span className='question_subhead question_subhead--smaller'>
                {t('raso_confirm_of_registration')}
              </span>
              <span className='question_head question_head--smaller'>
                {t('raso_download_pdf')}
              </span>
              <a
                href={`https://www.accountable.de/r/?t=${userData.rasoRef}&d=1`}
                onClick={() => {
                  sendAmplitudeData('WEB_SIGNUP_CLICKEDSHOWPDF');
                }}
              >
                <button className='form-raso_download'>
                  <img
                    src={DownloadIcon}
                    alt=''
                    className='form-raso_download_icon'
                  />
                  {t('raso_download_pdf_ref')} {userData.rasoRef}
                </button>
              </a>
              <br />
              <br />
              <center>
                <a
                  className='form-raso_dlink'
                  href='https://web.accountable.eu/'
                  onClick={(e) => {
                    e.preventDefault()
                    goToWeb(continuationToken);
                  }
                  }
                >
                  <button className='form_submit_btn thankyou-desktop'>
                    {t('raso_start')}
                  </button>
                </a>
                <a
                  href={t('download_app_url')}
                  onClick={() =>
                    sendAmplitudeData('WEB_SIGNUP_CLICKEDWEB', { to: 'app' })
                  }
                >
                  <button className='form_submit_btn thankyou-mobile'>
                    {t('raso_start')}
                  </button>
                </a>
              </center>
            </>
            : ''
          }
        </>
      )}
    </>
  );
};
export default Form;
